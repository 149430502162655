
import { useAppDispatch, useAppSelector } from '@src/hooks/redux';
import { FIXME } from '@src/types';
import { AnimatePresence, motion } from 'framer-motion';
import { ChevronsUpDown, Plus, Settings } from "lucide-react";
import { useEffect, useState } from "react";
import { Link, useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { Avatar, Button, DropdownMenu, Label, ScrollArea, Separator, Tooltip } from "ui";
import { useSidebar } from 'ui/components/Sidebar';
import { cn } from 'utils/cn';
import LockFeatureModal from '../tasks/LockFeaturePopup';
import CreateWorkspaceModal from '../workspace/CreateWorkspace';

const AdminRoles = ['admin', 'superadmin']; // Define AdminRoles array
const list = {
    visible: {
        opacity: 1,
        height: 'auto',
        transition: {
            when: 'beforeChildren',
            staggerChildren: 0.05,
        },
    },
    hidden: {
        opacity: 0,
        height: 0,
        transition: {
            when: 'afterChildren',
        },
    },
};

const listItem = {
    visible: { opacity: 1, y: 0 },
    hidden: { opacity: 0, y: -80 },
};
const MotionButton = motion(Button);
const WorkspaceDropdown = () => {
    const { workspaceData, selectedWorkspace } = useAppSelector(
        (state: { workspace: FIXME }) => state.workspace
    );
    const [_, setSearchParams] = useSearchParams();
    const { userData, isLoading } = useAppSelector((state) => state.user);
    const dispatch = useAppDispatch();
    const location = useLocation();
    const navigate = useNavigate();
    const [openWorkplace, setOpenWorkplace] = useState(false);
    const [openLockFeatureModal, setOpenLockFeatureModal] = useState(false);
    const [sortedWorkspaces, setSortedWorkspaces] = useState<{ personalWorkspaces: FIXME[], externalWorkspaces: FIXME[] } | null>(null);
    const [workspaceParagraph] = useState(
        'Create a new workspace with a trial of our Starter plan or upgrade this workspace to unlock more slots.'
    );
    const [workspaceLockTitle] = useState('Workspace');
    const [workspacePix] = useState(
        'https://wpfeedback-image.s3.us-east-2.amazonaws.com/media/locked-workspaces.png'
    );
    const [workspaceFeature] = useState('Workspace');

    const openCreateWorkplace = () => {
        setOpenWorkplace(!openWorkplace);
    };
    const { open, } = useSidebar();

    const sortWorkspaces = (workspaces: FIXME) => {
        const personalWorkspaces: FIXME[] = [];
        const externalWorkspaces: FIXME[] = [];

        workspaces.forEach((ws: FIXME) => {
            (ws.user_id == userData.normal_id
                ? personalWorkspaces
                : externalWorkspaces
            ).push(ws);
        });

        setSortedWorkspaces({ personalWorkspaces, externalWorkspaces });
    };


    const selectWorkspaceAndNavigate = (value: number) => {
        if (isLoading) {
            return;
        }
        // get the corresponding workspace from the workspaceData
        const workspace = workspaceData.find((workspace: FIXME) => workspace.id === value);
        // set the selected workspace in the local storage
        localStorage.setItem('selectedWorkspace', JSON.stringify(workspace));
        // set the selected workspace in the redux store
        dispatch({ type: 'SET_SELECTED_WORKSPACE', workspace });
        dispatch({
            type: 'STORE_SELECTED_WORKSPACE',
            workspaceId: { workspace_id: value },
        });
        // finally we clear the search params
        setSearchParams({});

        // navigate to the projects page
        if (location.pathname.includes('collaborate')) {
            navigate(`/projects`);
        }
    };

    const isUserAdmin = () => {
        return AdminRoles.indexOf(selectedWorkspace?.role) !== -1;
    };

    useEffect(() => {
        if (workspaceData.length > 0) {
            sortWorkspaces(workspaceData);
        }
    }, [workspaceData, userData]);

    const handleWorkspaceChange = (workspace: FIXME) => {
        if (!workspace.is_locked) {
            selectWorkspaceAndNavigate(workspace.id);
        }
    };

    const handleCloseLock = () => {
        setOpenLockFeatureModal(false);
    };



    return (
        <>
            <DropdownMenu>
                <Tooltip content={selectedWorkspace?.name} side="right" sideOffset={10} className={cn(open ? 'hidden' : '')}>
                    <DropdownMenu.Trigger asChild>
                        <Button variant="outline" aria-expanded={open} className={cn('hover:bg-tertiary/5', open ? 'h-12 px-2 w-full' : 'p-0 h-8 w-8')}>
                            <Avatar
                                src={selectedWorkspace?.icon}
                                alt={selectedWorkspace?.name}
                                id={selectedWorkspace?.id}
                                email={selectedWorkspace?.email}
                                title={selectedWorkspace?.name}
                                className={cn('text-xs rounded-lg', open ? 'h-8 w-8' : 'h-9 w-9')} />
                            {open &&
                                <>
                                    <div className="grid flex-1 text-left text-sm leading-tight">
                                        <span className="font-medium truncate">{selectedWorkspace?.name}</span>
                                        <span className="text-xs text-gray-400 truncate font-normal">Current Workspace</span>
                                    </div> <ChevronsUpDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
                                </>
                            }
                        </Button>
                    </DropdownMenu.Trigger>
                </Tooltip>
                <DropdownMenu.Content side="right" align="start" className="p-3 rounded-xl w-72" >
                    <AnimatePresence>
                        <ScrollArea>
                            <motion.div className=" w-full flex flex-col gap-1 h-full px-0 max-h-96" variants={list} initial="hidden"
                                animate="visible"
                                exit="hidden">
                                {sortedWorkspaces &&
                                    sortedWorkspaces.personalWorkspaces.length > 0 && (
                                        <>
                                            {sortedWorkspaces.personalWorkspaces.map((workspace, index) => (
                                                <DropdownMenu.Item key={index} className='p-0 w-full'>
                                                    <motion.div
                                                        variants={listItem}
                                                        className={cn('flex items-center p-2 rounded-lg justify-between hover:bg-tertiary hover:text-white cursor-pointer group/item overflow-hidden w-full', workspace.id === selectedWorkspace?.id && 'bg-tertiary/5 border border-tertiary')}
                                                        onClick={() => handleWorkspaceChange(workspace)}>
                                                        <div className="flex gap-2 items-center" >
                                                            <Avatar src={workspace?.icon} alt={workspace?.name} id={workspace?.id} className="h-8 w-8" title={workspace.name} email={workspace?.email} />
                                                            <div className="flex flex-col ">
                                                                <span className="font-medium truncate text-sm max-w-[170px] leading-none">{workspace.name}</span>
                                                                <span className={cn('text-xs text-gray-400  group-hover/item:text-gray-50')}>
                                                                    {workspace.id === selectedWorkspace?.id ?
                                                                        'Current Workspace' : 'Workspace'}</span>
                                                            </div>
                                                        </div>
                                                        {workspace.id === selectedWorkspace?.id &&
                                                            isUserAdmin() && (
                                                                <motion.div
                                                                    initial={{ opacity: 0, x: 36 }}
                                                                    animate={{ opacity: 1, x: 0 }}
                                                                    exit={{ opacity: 0, x: 36 }}
                                                                >
                                                                    {!workspace.is_locked ? (
                                                                        <Link to={`/settings?tab_flag=workspace`} onClick={
                                                                            (e) => {
                                                                                e.stopPropagation();
                                                                                dispatch({
                                                                                    type: 'SET_SELECTED_WORKSPACE_SETTINGS',
                                                                                    workspace,
                                                                                    id: workspace?.id,
                                                                                });
                                                                            }
                                                                        }>
                                                                            <Settings size={18} className="shrink-0" />
                                                                        </Link>
                                                                    ) : (
                                                                        <Settings size={18} className="shrink-0" />

                                                                    )}
                                                                </motion.div>
                                                            )}
                                                    </motion.div>
                                                </DropdownMenu.Item>

                                            ))}
                                        </>
                                    )}
                                {sortedWorkspaces &&
                                    sortedWorkspaces.externalWorkspaces.length > 0 && (
                                        <>
                                            <div className="flex items-center gap-2 text-xs font-semibold w-full ">
                                                <Label className="text-xs">External</Label>
                                                <Separator />
                                            </div>
                                            {sortedWorkspaces.externalWorkspaces.map((workspace, index) => (
                                                <motion.div className="flex items-center p-2 rounded-lg justify-between hover:bg-tertiary hover:text-white cursor-pointer group/item" key={index} variants={listItem}
                                                    onClick={() => handleWorkspaceChange(workspace)}>
                                                    <div className="flex gap-2 items-center">
                                                        <Avatar src={workspace?.icon} alt={workspace?.name} id={workspace?.id} className="h-8 w-8" title={workspace.name} email={workspace?.email} />
                                                        <div className="flex flex-col leading-tight">
                                                            <span className="font-medium truncate text-sm max-w-[170px]">{workspace.name}</span>
                                                            <span className="text-xs text-gray-400  group-hover/item:text-gray-50">
                                                                {workspace.id === selectedWorkspace?.id ?
                                                                    'Current Workspace' : 'Workspace'}</span>
                                                        </div>
                                                    </div>
                                                </motion.div>

                                            ))}
                                        </>
                                    )}

                            </motion.div>

                        </ScrollArea>
                        <DropdownMenu.Item className='p-0 bg-transparent hover:bg-transparent w-full active:bg-transparent rounded-lg mt-2'>
                            <MotionButton onClick={() => openCreateWorkplace()}
                                className=" w-full" size="sm"
                                leftIcon={<Plus size={18} />}
                                initial={{ opacity: 0, y: 36 }}
                                animate={{ opacity: 1, y: 0 }}
                                exit={{ opacity: 0, y: 36 }}
                                transition={{ duration: 0.2, delay: 0.75 }}>Create a New Workspace</MotionButton>
                        </DropdownMenu.Item>


                    </AnimatePresence>

                </DropdownMenu.Content>
                {
                    openWorkplace && (
                        <CreateWorkspaceModal
                            open={openWorkplace}
                            openCreateWorkplace={openCreateWorkplace}
                            title={`Create a new Workspace`}
                            setOpenLockFeatureModal={setOpenLockFeatureModal}
                        />
                    )
                }
            </DropdownMenu >
            <LockFeatureModal
                openLockFeatureModal={openLockFeatureModal}
                lockTitle={workspaceLockTitle}
                lockParagraph={workspaceParagraph}
                lockImg={workspacePix}
                handleCloseLock={handleCloseLock}
                upSellUrl={userData?.plan?.upsell_url}
                featureString={workspaceFeature}
                closeOnAction={true}
            />
        </>

    );


}
export default WorkspaceDropdown;