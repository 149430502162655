import { CONSTANT_TASK_LISTING_PAGE_LIMIT } from 'utils/constants';
import API from '../api';

export function fetchTaskList(
  params,
  limit = CONSTANT_TASK_LISTING_PAGE_LIMIT
) {
  const [sortField, sortOrder = 'desc'] = params.order_by.split(':');
  return API.get(
    `/site/tasks/${params.websiteId}?offset=${params.offset}&limit=${limit}&type=task&order_by=${sortField ?? ''}&direction=${sortOrder ?? 'desc'}`
  );
}

// export function fetchBoardTaskList(id, offset = 0, limit = CONSTANT_TASK_LISTING_PAGE_LIMIT) {
//     return API.get(`/site/tasks/${id}?offset=${offset}&limit=${limit}&type=board`);
// }

export function fetchBoardColumnTask(params) {
  const {
    websiteId,
    offset = 0,
    limit = CONSTANT_TASK_LISTING_PAGE_LIMIT,
    task_category,
    column_type,
  } = params;
  return API.get(
    `/site/tasks/${websiteId}?offset=${offset}&limit=${limit}&type=board&task_category=${task_category}&column_type=${column_type}`
  );
}
export function fetchNextBoardTaskStatus(params) {
  const {
    websiteId,
    offset = 0,
    limit = CONSTANT_TASK_LISTING_PAGE_LIMIT,
    taskCategory,
    columnType,
  } = params;
  return API.get(
    `/site/tasks/${websiteId}?offset=${offset}&limit=${limit}&type=board&task_category=${taskCategory}&column_type=${columnType}`
  );
}

export function fetchTaskFilters(id) {
  return API.get(`/site/get-filter-data/${id}`);
}

export function fetchTaskAttributes(id) {
  return API.get(`/task/get-task-details/${id}`);
}

export function notifyServerToUpdateNewUser(taskId) {
  return API.get(`/task/fire-task-viewing-event/${taskId}`);
}
// for single task deletion
// export function deleteTask(taskId) {
//     return API.get(`/task/delete-task/${taskId}`);
// }
// for bulk tasks deletion
export function deleteMultiTasks(taskIds) {
  return API.post(`/task/bulk-delete-task`, taskIds);
}

export function fetchAllTasks({
  offset = 0,
  limit = CONSTANT_TASK_LISTING_PAGE_LIMIT,
  workspace_id = null,
}) {
  return API.get(
    `/task/get-all-tasks?offset=${offset}&limit=${limit}&type=task&workspace_id=${workspace_id}`
  );
}

export function fetchAllBoardTasks(
  offset = 0,
  limit = CONSTANT_TASK_LISTING_PAGE_LIMIT
) {
  return API.get(
    `/task/get-all-tasks?offset=${offset}&limit=${limit}&type=board`
  );
}

export function fetchFilterAllTasks() {
  return API.get('/site/get-filter-data');
}

export function fetchNewGeneralTaskAttributes(websiteId) {
  return API.get(`/site/pages/${websiteId}`);
}

export function updateSingleTaskAttributes(params) {
  return API.post('/task/bulkupdate-task-details', params);
}
export function updateTaskAttributes(params) {
  return API.post('/task/bulkupdate-task-details', params);
}

export function sendMessage(params) {
  return API.post('/comment/store', params);
}

export function attachFile(params) {
  return API.post('/comment/upload-file', params);
}

// the data return by the api returns true even when the message says "image not found",
// this should return the status as false and give the same error message
export function deleteS3Images(params) {
  return API.post('/delete-s3-images', params);
}

export function filterTasks(url, reqBody, workspace_id) {
  return API.post(`${url}?workspace_id=${workspace_id}`, reqBody);
}

export function updateEstimatedTime(taskId, newTime) {
  return API.post(`/task/set-estimated-time/${taskId}`, {
    estimated_time: newTime,
  });
}

// export function updateSpentTime(taskId, spentTime) {
//     return API.post(`/task/set-spent-time/${taskId}`, {spent_time: spentTime});
// }

export function saveGeneralTask(params) {
  return API.post(`/task/store`, params);
}

export function fetchTagsSuggestions(params) {
  return API.post(`/task/task-tag-suggestion`, params);
}

// export function updateSiteOrder(oldIndex, newIndex, site_id) {
//     return API.get(`/site/update-site-order?site=${site_id}&oldIndex=${oldIndex}&newIndex=${newIndex}`);
// }

export function searchTaskCategory(reqBody, workspace_id) {
  return API.post(
    `/task/get-tasks-search-category?workspace_id=${workspace_id}`,
    reqBody
  );
}

export function updateBoardTaskOrders(params) {
  return API.post('/task/update-task-order', params);
}

export function exportTaskData(params) {
  return API.post('/task/export-task', params);
}

// export function updateAllSiteOrder(params) {
//     return API.post("/site/update-all-site-order", params);
// }

export function sendImageToMedia(commentId) {
  return API.post(`comment/sendImageToMedia?id=${commentId}`);
}

export function editComment(params) {
  return API.post(`/comment/updateContent`, params);
}

export function deleteComment(params) {
  return API.post(`/comment/trash`, params);
}

export function clickupGetFolders() {
  return API.get('/clickup/get_folders');
}

export function teamWorkGetProjects() {
  return API.get('/teamwork/get_teamwork_projects');
}

export function asanaGetProjects() {
  return API.get('/asana/get_projects');
}

export function clickupGetTaskList(params) {
  return API.post('/clickup/task_lists', params);
}

export function teamWorkGetTaskList(params) {
  return API.get(`/teamwork/get_teamwork_task_list/${params}`);
}

export function deleteCommentFiles(params) {
  return API.post(`/collaborate/comments/delete-files`, params);
}

export function teamWorkGetTask(params) {
  return API.get(`/teamwork/get_tasks/${params}`);
}

export function asanaGetSection(params) {
  return API.post('/asana/get_sections', params);
}

// create task on ClickUp
export function clickupCreateTask(params) {
  return API.post('/clickup/create_task', params);
}

export function teamWorkCreateTask(params) {
  return API.post('/teamwork/create_task', params);
}

export function asanaCreateTask(params) {
  return API.post('/asana/push_a_task', params);
}
// create tasks on Atarim from ClickUp Tasks List
export function clickupDeployTasks(params) {
  return API.post('/clickup/deploy_clickup_tasks', params);
}

export function asanaDeployTasks(params) {
  return API.post('/asana/deploy_asana_tasks', params);
}

export function teamWorkDeployTasks(params) {
  return API.post('/teamwork/deploy_teamwork_tasks', params);
}

export function jiraGetProjects(selectedWorkspaceId) {
  return API.get(
    `/jira/get_projects${selectedWorkspaceId ? `?workspace_id=${selectedWorkspaceId}` : ''}`
  );
}

export function jiraCreateTask(params) {
  return API.post('/jira/create_task', params);
}

export function jiraDeployTasks(params) {
  return API.post('/jira/deploy_jira_tasks', params);
}

export function basecampGetProjects() {
  return API.get('/basecamp/get_projects');
}

export function basecampGetTodoList(params) {
  return API.post('/basecamp/get_todo_lists', params);
}

export function basecampCreateTask(params) {
  return API.post('/basecamp/create_task', params);
}

export function basecampDeployTasks(params) {
  return API.post('/basecamp/deploy_basecamp_tasks', params);
}

export function trelloGetboards() {
  return API.get('/trello/get_boards');
}

export function trelloGetTaskList(params) {
  return API.post('/trello/get_lists', params);
}

export function trelloCreateTask(params) {
  return API.post('/trello/create_task', params);
}

export function trelloDeployTasks(params) {
  return API.post('/trello/deploy_trello_tasks', params);
}

export function fetchTriggers(workspaceId) {
  return API.get(`/automation/trigger/getall?workspace_id=${workspaceId}`);
}

export function fetchAutomationAction(workspaceId) {
  return API.get(`/automation/action/getall?workspace_id=${workspaceId}`);
}

export function fetchAutomationCondition(workspaceId) {
  return API.get(`/automation/condition/getall?workspace_id=${workspaceId}`);
}

export function createCustomAutomation(params, selectedWorkspaceId) {
  return API.post(
    `/automation/create${selectedWorkspaceId ? `?workspace_id=${selectedWorkspaceId}` : ''}`,
    params
  );
}

export function fetchAutomationWorkflow(selectedWorkspaceId) {
  return API.get(
    `/automation/workflow/getall${selectedWorkspaceId ? `?workspace_id=${selectedWorkspaceId}` : ''}`
  );
}

export function fetchAutomationTag(params) {
  return API.post('/fetch_tags', params);
}

export function changeAutomationStatus(params) {
  return API.post(`/automation/workflow/toggle/${params.id}`, params);
}

export function deleteWorkflow(params) {
  return API.trash(`/automation/workflow/delete/${params}`);
}

export function fetchAutomationDetail(params) {
  return API.get(`/automation/workflow/getone/${params}`);
}

export function editCustomAutomation(params, id) {
  return API.post(`/automation/workflow/edit/${id}`, params);
}

export function createAutomationTemplate(params) {
  return API.post('/automation/create_template', params);
}

export function fetchAutomationTemplate(workspaceId) {
  return API.get(`/automation/all_templates?workspace_id=${workspaceId}`);
}

export function fetchAutomationTemplateDetail(params) {
  return API.get(`/automation/get_template/${params}`);
}

export function fetchAllSnippetSetting(selectedWorkspaceId) {
  return API.get(
    `/snippets${selectedWorkspaceId ? `?workspace_id=${selectedWorkspaceId}` : ''}`
  );
}

export function fetchAllCollabSnippetSetting(
  selectedWorkspaceId,
  taskId,
  siteId
) {
  return API.get(
    `collaborate/site/${siteId}/snippets${selectedWorkspaceId ? `?workspace_id=${selectedWorkspaceId}&all_Details=true&parse_content=true&task_id=${taskId}` : `?all_Details=true&parse_content=true&task_id=${taskId}`}`
  );
}

export function fetchSnippetDetailSetting(id, taskId) {
  return API.get(
    `/snippets/${id}/detail?parse_content=${true}&task_id=${taskId}`
  );
}

export function mondayGetboards() {
  return API.get('/monday/get_boards');
}

export function mondayGetGroupList(params) {
  return API.post('/monday/get_groups', params);
}

export function mondayCreateTask(params) {
  return API.post('/monday/create_task', params);
}

export function mondayDeployTasks(params) {
  return API.post('/monday/deploy_tasks', params);
}

export function fetchAssignProjects() {
  return API.get(
    `/site?offset=0&limit=25&userId=&search_keyword=&filter_by=&site_order=&for=assign_to_project`
  );
}

export function fetchAssignProjectSearch(keyword) {
  return API.get(
    `/site?offset=0&limit=25&userId=&search_keyword=${keyword}&filter_by=&site_order=&for=assign_to_project`
  );
}

export function fetchNextAssignProjects(offset) {
  return API.get(
    `/site?offset=${offset}&limit=25&userId=&search_keyword=&filter_by=&site_order=&for=assign_to_project`
  );
}

export function fetchBoardTaskLowUrgency(
  params,
  offset = 0,
  limit = CONSTANT_TASK_LISTING_PAGE_LIMIT
) {
  return API.get(
    `/site/tasks/${params.websiteId}?offset=${offset}&limit=${limit}&type=board&task_category=${params.task_category}&column_type=${params.column_type}`
  );
}
export function fetchNextBoardTaskUrgency(
  params,
  limit = CONSTANT_TASK_LISTING_PAGE_LIMIT
) {
  return API.get(
    `/site/tasks/${params.websiteId}?offset=${params.offset}&limit=${limit}&type=board&task_category=${params.taskCategory}&column_type=${params.columnType}`
  );
}

export function clickupTaskList() {
  return API.post('/clickup/space-list');
}
