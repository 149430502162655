import API from '../api';

export function fetchGlobalSettings(selectedWorkspaceId) {
  return API.get(
    `user/global-settings${selectedWorkspaceId ? `?workspace_id=${selectedWorkspaceId}` : ''}`
  );
}

export function updateGlobalSettings(paramObj, selectedWorkspaceId) {
  return API.post(
    `/user/update-global-settings${selectedWorkspaceId ? `?workspace_id=${selectedWorkspaceId}` : ''}`,
    paramObj
  );
}

export function resetSettings() {
  return API.get('/user/reset-global-settings');
}

export function newWebhookToken() {
  return API.get('/new_webhook_token');
}

export function changeWebhookStatus(obj) {
  return API.post('/change_webhook_status', obj);
}

export function changeCustomWebhook(obj) {
  return API.post('/change_webhook_url', obj);
}

export function changeSlackSetting(obj, selectedWorkspaceId) {
  return API.post(
    `/slack/change_slack_settings${selectedWorkspaceId ? `?workspace_id=${selectedWorkspaceId}` : ''}`,
    obj
  );
}

export function changeIntegrationSetting(obj, selectedWorkspaceId) {
  return API.post(
    `/user/update-global-settings${selectedWorkspaceId ? `?workspace_id=${selectedWorkspaceId}` : ''}`,
    obj
  );
}

export function changeAutomaticTaskSetting(obj, selectedWorkspaceId) {
  return API.post(
    `/change_automatic_task_settings${selectedWorkspaceId ? `?workspace_id=${selectedWorkspaceId}` : ''}`,
    obj
  );
}

export function verifyForwarding() {
  return API.post('/receive-incoming-email');
}

export function verifyDomain(paramObj, selectedWorkspaceId) {
  return API.post(
    `/user/verify-domain?workspace_id=${selectedWorkspaceId}`,
    paramObj
  );
}

// clickUp integration
export function clickupCompleteAuth(paramObj, selectedWorkspaceId) {
  return API.post(
    `/clickup/complete_authentication${selectedWorkspaceId ? `?workspace_id=${selectedWorkspaceId}` : ''}`,
    paramObj
  );
}

export function clickupGetTeams(selectedWorkspaceId) {
  return API.get(
    `/clickup/get_clickup_teams${selectedWorkspaceId ? `?workspace_id=${selectedWorkspaceId}` : ''}`
  );
}

export function clickupStoreTeam(paramObj, selectedWorkspaceId) {
  return API.post(
    `/clickup/team_id/store${selectedWorkspaceId ? `?workspace_id=${selectedWorkspaceId}` : ''}`,
    paramObj
  );
}

export function clickupGetWorkspaces(selectedWorkspaceId) {
  return API.get(
    `/clickup/get_workspaces${selectedWorkspaceId ? `?workspace_id=${selectedWorkspaceId}` : ''}`
  );
}

export function asanaGetWorkspaces(selectedWorkspaceId) {
  return API.get(
    `/asana/get_asana_workspace${selectedWorkspaceId ? `?workspace_id=${selectedWorkspaceId}` : ''}`
  );
}

export function asanaGetTeam(selectedWorkspaceId) {
  return API.get(
    `/asana/get_team${selectedWorkspaceId ? `?workspace_id=${selectedWorkspaceId}` : ''}`
  );
}

export function clickupStoreWorkspace(paramObj, selectedWorkspaceId) {
  return API.post(
    `/clickup/workspace/store${selectedWorkspaceId ? `?workspace_id=${selectedWorkspaceId}` : ''}`,
    paramObj
  );
}

export function asanaStoreWorkspace(paramObj, selectedWorkspaceId) {
  return API.post(
    `/asana/workspace/store${selectedWorkspaceId ? `?workspace_id=${selectedWorkspaceId}` : ''}`,
    paramObj
  );
}

export function asanaStoreTeams(paramObj, selectedWorkspaceId) {
  return API.post(
    `/asana/team/store${selectedWorkspaceId ? `?workspace_id=${selectedWorkspaceId}` : ''}`,
    paramObj
  );
}

export function jiraGetWebsites(selectedWorkspaceId) {
  return API.get(
    `/jira/get_jira_sites${selectedWorkspaceId ? `?workspace_id=${selectedWorkspaceId}` : ''}`
  );
}

export function jiraStoreWebsite(paramObj, selectedWorkspaceId) {
  return API.post(
    `/jira/store_jira_site${selectedWorkspaceId ? `?workspace_id=${selectedWorkspaceId}` : ''}`,
    paramObj
  );
}

export function clickupGetFoldersSettings(params, selectedWorkspaceId) {
  return API.get(
    `/clickup/get_folders/${selectedWorkspaceId ? `?workspace_id=${selectedWorkspaceId}` : ''}`
  );
}

export function clickupGetTaskListSetting(params, selectedWorkspaceId) {
  return API.post(
    `/clickup/task_lists${selectedWorkspaceId ? `?workspace_id=${selectedWorkspaceId}` : ''}`,
    params
  );
}

export function storeIntegrationRequirement(params, selectedWorkspaceId) {
  return API.post(
    `/store_integration_requirements${selectedWorkspaceId ? `?workspace_id=${selectedWorkspaceId}` : ''}`,
    params
  );
}

export function asanaGetProjectsSettings(params, selectedWorkspaceId) {
  return API.get(
    `/asana/get_projects${selectedWorkspaceId ? `?workspace_id=${selectedWorkspaceId}` : ''}`
  );
}

export function asanaGetSectionSetting(params, selectedWorkspaceId) {
  return API.post(
    `/asana/get_sections${selectedWorkspaceId ? `?workspace_id=${selectedWorkspaceId}` : ''}`,
    params
  );
}

export function teamworkGetProjectsSettings(params, selectedWorkspaceId) {
  return API.get(
    `/teamwork/get_teamwork_projects${selectedWorkspaceId ? `?workspace_id=${selectedWorkspaceId}` : ''}`
  );
}

export function teamworkGetTaskListSetting(params, num, selectedWorkspaceId) {
  return API.get(
    `/teamwork/get_teamwork_task_list/${params}${selectedWorkspaceId ? `?workspace_id=${selectedWorkspaceId}` : ''}`
  );
}

export function jiraGetProjectsSettings(params, selectedWorkspaceId) {
  return API.get(
    `/jira/get_projects${selectedWorkspaceId ? `?workspace_id=${selectedWorkspaceId}` : ''}`
  );
}

export function changeMainwpSetting(obj) {
  return API.post('/change_mainwp_settings', obj);
}

export function connectRocketSetting(obj) {
  return API.post('/rocket/connect', obj);
}

export function basecampGetProjectsSettings(params, selectedWorkspaceId) {
  return API.get(
    `/basecamp/get_projects${selectedWorkspaceId ? `?workspace_id=${selectedWorkspaceId}` : ''}`
  );
}

export function basecampGetTaskSetting(params, selectedWorkspaceId) {
  return API.post(
    `/basecamp/get_todo_lists${selectedWorkspaceId ? `?workspace_id=${selectedWorkspaceId}` : ''}`,
    params
  );
}

export function trelloGetProjectsSettings(params, selectedWorkspaceId) {
  return API.get(
    `/trello/get_automatic_boards/${params}${selectedWorkspaceId ? `?workspace_id=${selectedWorkspaceId}` : ''}`
  );
}

export function trelloGetListSetting(params, selectedWorkspaceId) {
  return API.post(
    `/trello/get_automatic_lists/${params?.board_id}${selectedWorkspaceId ? `?workspace_id=${selectedWorkspaceId}` : ''}`,
    params
  );
}

export function changeInstawpSetting(obj) {
  return API.post('/instawp_api_key', obj);
}

export function createSnippetSetting(obj, selectedWorkspaceId) {
  return API.post(
    `/snippets/create${selectedWorkspaceId ? `?workspace_id=${selectedWorkspaceId}` : ''}`,
    obj
  );
}

export function fetchAllSnippetSetting(selectedWorkspaceId) {
  return API.get(
    `/snippets${selectedWorkspaceId ? `?workspace_id=${selectedWorkspaceId}` : ''}`
  );
}

export function fetchSnippetDetailSetting(id) {
  return API.get(`/snippets/${id}/detail`);
}

export function deleteSnippetSetting(params) {
  return API.post(`/snippets/delete`, params);
}

export function updateSnippetSetting(obj) {
  return API.post(`/snippets/update`, obj);
}

export function createSnippetTemplateSetting(obj) {
  return API.post('/snippets/templates/create', obj);
}

export function fetchAllSnippetTemplateSetting() {
  return API.get(`/snippets/templates/all`);
}

export function importSnippetTemplateSetting(obj, selectedWorkspaceId) {
  return API.post(
    `snippets/templates/import${selectedWorkspaceId ? `?workspace_id=${selectedWorkspaceId}` : ''}`,
    obj
  );
}

export function mondayGetWorkspace(selectedWorkspaceId) {
  return API.get(
    `/monday/get_workspace${selectedWorkspaceId ? `?workspace_id=${selectedWorkspaceId}` : ''}`
  );
}

export function mondayStorespace(paramObj, selectedWorkspaceId) {
  return API.post(
    `/monday/store_workspace${selectedWorkspaceId ? `?workspace_id=${selectedWorkspaceId}` : ''}`,
    paramObj
  );
}

export function changeMondaySetting(obj, selectedWorkspaceId) {
  return API.post(
    `/monday/store_key${selectedWorkspaceId ? `?workspace_id=${selectedWorkspaceId}` : ''}`,
    obj
  );
}

export function mondayGetBoardsSettings(params, selectedWorkspaceId) {
  return API.get(
    `/monday/get_automatic_boards/${params}${selectedWorkspaceId ? `?workspace_id=${selectedWorkspaceId}` : ''}`
  );
}

export function mondayGetGroupSetting(params, selectedWorkspaceId) {
  return API.post(
    `/monday/get_automatic_groups/2${selectedWorkspaceId ? `?workspace_id=${selectedWorkspaceId}` : ''}`,
    params
  );
}

export function validateEmailInbox(paramObj) {
  return API.post('/mailbox/forward-email', paramObj);
}
