import { motion, Variants } from 'framer-motion';
import React from 'react';

// Variants for the outline drawing animation
const outlineVariants: Variants = {
  hidden: { pathLength: 0 }, // Initial state: path is not drawn
  visible: { // Animating state: draw the path
    pathLength: 1,
    transition: {
      duration: 0.5,
      ease: 'easeInOut',
      repeat: Infinity,
      repeatType: 'reverse',
      repeatDelay: 1, // Wait after reversing before repeating
    },
  },
  static: { // Static state: path is fully drawn
    pathLength: 1,
  },
};

// Variants for the fill animation
const fillVariants: Variants = {
  hidden: { fillOpacity: 0 }, // Initial state: fill is transparent
  visible: { // Animating state: fade fill in and out
    fillOpacity: [0, 1, 1, 0], // Keyframes: Fade in, stay, fade out
    transition: {
      duration: 1,
      delay: 0.5, // Start fill slightly after outline starts drawing
      ease: 'easeInOut',
      repeat: Infinity,
      repeatDelay: 1.5, // Adjusted to better sync with outline total cycle (0.5*2 + 1 = 2s)
      // Fill cycle: 1s duration + 1.5s delay = 2.5s. May need further fine-tuning
      // Original repeatDelay was 2s (total 3s cycle).
    },
  },
  static: { // Static state: fill is fully opaque
    fillOpacity: 1,
  },
};

// Updated Props interface to include 'play'
interface AnimatedLoaderProps {
  size?: number;
  play?: boolean; // Controls animation state, true by default
}

export function AnimatedLoader({ size = 90, play = true }: AnimatedLoaderProps): React.ReactElement {
  // Determine the target animation state based on the 'play' prop
  const targetVariant = play ? 'visible' : 'static';
  // Determine the initial state. If not playing, start directly at the static state.
  const initialVariant = play ? 'hidden' : 'static';

  return (
    <div className="flex h-auto items-center justify-center">
      <motion.svg
        width={size}
        // Maintain aspect ratio based on viewBox
        height={(size * 90) / 93}
        viewBox="0 0 93 90"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        {/* Shape 1 - Outline */}
        <motion.path
          d="M79.3656 66.0739L63.3768 71.9458L72.2283 88.9982L91.2922 88.9759L79.3656 66.0739Z"
          variants={outlineVariants}
          initial={initialVariant} // Set initial based on play state
          animate={targetVariant}  // Animate to 'visible' or 'static'
          stroke="#052055"
          strokeWidth={1.5} // Added for better visibility if stroke/fill are same color sometimes
        />
        {/* Shape 1 - Fill */}
        <motion.path
          d="M79.3656 66.0739L63.3768 71.9458L72.2283 88.9982L91.2922 88.9759L79.3656 66.0739Z"
          variants={fillVariants}
          initial={initialVariant} // Set initial based on play state
          animate={targetVariant}  // Animate to 'visible' or 'static'
          fill="#052055"
        />
        {/* Shape 2 - Outline */}
        <motion.path
          d="M20 89L32 66L89.2084 43.7949L20 89Z"
          variants={outlineVariants}
          initial={initialVariant}
          animate={targetVariant}
          stroke="#6D5DF3"
          strokeLinejoin="round"
          strokeWidth={1.5}
        />
        {/* Shape 2 - Fill */}
        <motion.path
          d="M20 89L32 66L89.2084 43.7949L20 89Z"
          variants={fillVariants}
          initial={initialVariant}
          animate={targetVariant}
          fill="#6D5DF3"
        />
        {/* Shape 3 - Outline */}
        <motion.path
          d="M46.1411 2.03317L1 88.976H20.0744L46.1354 38.7298L54.5 57L71.5 50.5L46.1411 2.03317Z"
          variants={outlineVariants}
          initial={initialVariant}
          animate={targetVariant}
          stroke="#052055"
          strokeWidth={1.5}
        />
        {/* Shape 3 - Fill */}
        <motion.path
          d="M46.1411 2.03317L1 88.976H20.0744L46.1354 38.7298L54.5 57L71.5 50.5L46.1411 2.03317Z"
          variants={fillVariants}
          initial={initialVariant}
          animate={targetVariant}
          fill="#052055"
        />
      </motion.svg>
    </div>
  );
}

export default AnimatedLoader;